._hideQuotation_1eugr_1 > .gmail_quote {
  display: none;
}

._tailwindRestore_1eugr_5 ul * {
  list-style-type: disc;
  margin-left: 16px;
}

._tailwindRestore_1eugr_5 ol * {
  list-style-type: decimal;
  margin-left: 20px;
}

._tailwindRestore_1eugr_5 a[detracked=t] {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
  -webkit-text-decoration-style: dotted !important;
          text-decoration-style: dotted !important;

  /* background: transparent url("@mui/material-icons/Block") center right no-repeat; */
}