._wrapper_xczvy_1 {
  position: relative;
  width: 100%;
  height: 100%;
}

._gradient_xczvy_7 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  overflow: hidden;

  background: linear-gradient(180deg, #42a5f5 0%, #3949ab 90%);
}

._antigradient_xczvy_19 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  background: linear-gradient(20deg, #e91e63 -40%, rgba(0, 0, 0, 0) 70%);
}
