._wrapper_11uqg_1 {
  display: grid;
  grid-template-rows: -webkit-min-content 1fr;
  grid-template-rows: min-content 1fr;
}

._header_11uqg_6 {
  display: grid;

  grid-template-columns: -webkit-min-content 1fr;

  grid-template-columns: min-content 1fr;
  -moz-column-gap: 12px;
       column-gap: 12px;
}
