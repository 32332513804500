._wrapper_1glp9_1 {
  display: grid;
  grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
  grid-template-columns: min-content 1fr min-content;
}

._wrapperExpanded_1glp9_6 {
  display: grid;
  grid-template-columns: 1fr -webkit-min-content;
  grid-template-columns: 1fr min-content;
}
