._wrapper_1i1md_1 {
  display: grid;
  grid-template-columns: 1fr -webkit-min-content;
  grid-template-columns: 1fr min-content;
}

._wrapperExpanded_1i1md_6 {
  display: grid;
  grid-template-columns: 1fr -webkit-min-content;
  grid-template-columns: 1fr min-content;
}
